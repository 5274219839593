<template>
  <div class="fwsdetail">
    <el-steps :active="2" align-center>
      <el-step
        v-if="user_state == 0"
        title="审核中"
        description="服务商资质审核中，请注意接听电话"
        ><template slot="icon"
          ><img src="@/assets/image/enter/sh.png" class="stepicon" /></template
      ></el-step>
      <el-step
        v-else-if="user_state == 1"
        title="审核结果"
        description="服务商已经通过"
        ><template slot="icon"
          ><img src="@/assets/slices/step444.png" class="stepicon" /></template
      ></el-step>
      <el-step
        v-else-if="user_state == 2"
        title="审核结果"
        description="服务商未通过申请"
        ><template slot="icon"
          ><img src="@/assets/slices/step444.png" class="stepicon" /></template
      ></el-step>
      <el-step v-else title="提交申请" description="您暂未提交升级服务商申请">
        <template slot="icon"
          ><img src="@/assets/image/enter/qs.png" class="stepicon"
        /></template>
        <template slot="title"></template>
      </el-step>
    </el-steps>
  </div>
</template>
       

<script>
import { automatically } from "../../../API/ZZD/storage";
export default {
  components: {},
  data() {
    return {
      activeName: "Detail",
      user_state: "",
    };
  },
  mounted() {
    let id = localStorage.getItem("ZZId");
    automatically(id).then((res) => {
      // state 0为待审批 1为已审批，2为已驳回  无为没有申请过
      this.user_state = res.data.data.state;
    });
  },
  methods: {},
};
</script>

<style>
.stepicon {
  width: 70px;
}
.el-step__icon.is-text {
  border: none;
}
.el-step__line-inner {
  display: none;
}
.el-step.is-horizontal .el-step__line {
  border-top: 2px dashed #ff1918;
  border-radius: 50%;
  height: 0;
  background-color: transparent;
}
.el-step__title.is-finish,
.el-step__description.is-finish,
.el-step__title.is-process,
.el-step__description.is-process {
  color: #ff1918;
}
.fwsdetail {
  box-sizing: border-box;
  padding: 40px 0;
}
</style>